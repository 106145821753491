<template>
  <div class="body">
    <b-tabs horizontal nav-wrapper-class="nav-bottom">
      <b-tab active title="Plan General Info">
        <b-row class="d-flex justify-content-between header mb-2 mt-3">
          <b-col>
            <h1 class="serverName text-primary ml-2">
              {{ `${planInfo.name.toUpperCase()}` }}
            </h1>
          </b-col>
          <!-- <b-button
            class="mr-2"
            variant="relief-success"
            @click="handleAddOrganization"
          >
            Add Organization
          </b-button> -->
          <!-- <b-button class="mr-2" variant="relief-info" @click="handleEdit">
            EDIT
          </b-button> -->
        </b-row>
        <b-row class="ml-2">
          Created at:
          <strong class="text-primary pl-47 pr-47">
            {{ planInfo.created_at }}</strong
          >
        </b-row>

        <hr class="my-2" />
        <b-col class="ml-5" cols="6">
          <b-row>
            <b-col class="mb-1" cols="12">
              <b-form-group
                label="Plan Name"
                label-for="plan-name"
                label-cols-md="4"
              >
                <b-input-group v-if="planInfo.name" class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="BoxIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="plan-name"
                    placeholder="Plan Name"
                    :value="`  ${planInfo.name}`"
                    readonly
                  />
                  <b-input-group-append is-text>
                    <b-button
                      variant="ml-1 btn btn-warning text-white py-25 px-75"
                      @click="editPlan('Name')"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <div v-else>
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12">
              <b-form-group
                label="Plan Category"
                label-for="plan-category"
                label-cols-md="4"
              >
                <b-input-group v-if="planInfo" class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LayersIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="plan-category"
                    placeholder="Plan Category"
                    readonly
                    :value="`  ${
                      planInfo.enum_category.label
                        ? planInfo.enum_category.label
                        : 'Not Informed'
                    }`"
                  />
                  <b-input-group-append is-text>
                    <b-button
                      variant="ml-1 btn btn-warning text-white py-25 px-75"
                      @click="editPlan('Enum_Category')"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <div v-else>
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12">
              <b-form-group
                label="Status"
                label-for="server-status"
                label-cols-md="4"
              >
                <b-input-group v-if="planInfo.status" class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PowerIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="server-status"
                    placeholder="Server Status"
                    readonly
                    :value="`  ${planInfo.status}`"
                  />
                  <b-input-group-append is-text>
                    <b-button
                      variant="ml-1 btn btn-warning text-white py-25 px-75"
                      @click="editPlan('Active')"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <div v-else>
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12">
              <b-form-group
                label="Private"
                label-for="plan-private"
                label-cols-md="4"
              >
                <b-form-checkbox
                  id="plan-private"
                  :class="`custom-control-danger`"
                  class="mt-1"
                  @change="editIsPrivate()"
                  v-model="planInfo.is_private"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" class="text-light" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" class="text-danger" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-tab>
      <b-tab title="Configuration List">
        <b-row class="d-flex justify-content-between header mb-2 mt-3">
          <b-col>
            <h2 class="serverName text-primary ml-2">
              {{ `${planInfo.name.toUpperCase()} Config List` }}
            </h2>
          </b-col>
          <b-button
            class="mr-2"
            variant="relief-success"
            @click="handleAddPlanConfiguration"
          >
            Add Configuration
          </b-button>
          <!-- <b-button class="mr-2" variant="relief-info" @click="handleEdit">
            EDIT
          </b-button> -->
        </b-row>

        <hr class="my-2" />
        <BsTable
          tableName="planConfig"
          :fields="tableFields.planConfig"
          :actions="actions.planConfig"
          :has_paginator="true"
          :show_filter="true"
        />
      </b-tab>
      <b-tab title="Plan Visibility">
        <b-row class="d-flex justify-content-between header mb-2 mt-3">
          <b-col>
            <h2 class="serverName text-primary ml-2">
              {{ `${planInfo.name.toUpperCase()} Visibility` }}
            </h2>
          </b-col>
          <b-button
            class="mr-2"
            variant="relief-success"
            @click="handleAddOrganizationToPlan"
          >
            Add Organization
          </b-button>
          <!-- <b-button class="mr-2" variant="relief-info" @click="handleEdit">
            EDIT
          </b-button> -->
        </b-row>

        <hr class="my-2" />
        <BsTable
          tableName="planVisibility"
          :fields="tableFields.planVisibility"
          :actions="actions.planVisibility"
          :has_paginator="true"
          :show_filter="true"
        />
      </b-tab>
    </b-tabs>

    <base-modal />
    <hr class="my-2" />
  </div>
</template>

<script>
import BsTable from "@/layouts/components/BsTable.vue";
import BaseModal from "@/layouts/components/BaseModal.vue";
import custom from "@/custom";
import moment from "moment";
import { makeToast } from "@/layouts/components/Popups";

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BInputGroupAppend,
    BFormTextarea,
    BaseModal,
    BsTable,
    BSkeleton,
    BTabs,
    BTab,
    BCardText,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      planInfo: [],
      tableFields: {
        planConfig: [
          {
            key: "id",
            label: this.$t("common.table.fields.id"),
            sortable: false,
          },
          {
            key: "name",
            label: this.$t("common.table.fields.name"),
            sortable: false,
          },
          {
            key: "main_config",
            label: this.$t("plans.plan_config.main"),
            sortable: false,
          },
          {
            key: "status",
            label: this.$t("common.table.fields.status"),
            sortable: false,
          },
          {
            key: "price",
            label: this.$t("plans.plan_config.price"),
            sortable: false,
          },
          {
            key: "created_at",
            label: this.$t("common.table.fields.created_at"),
            sortable: false,
          },
          {
            key: "actions",
            label: this.$t("common.table.fields.actions"),
          },
        ],
        planVisibility: [
          {
            key: "id",
            label: this.$t("common.table.fields.id"),
            sortable: true,
          },
          {
            key: "name",
            label: this.$t("plans.plan_visibility.organization"),
            sortable: true,
          },
          {
            key: "created_by",
            label: this.$t("plans.plan_visibility.created_by"),
            sortable: true,
          },
          {
            key: "created_at",
            label: this.$t("common.table.fields.created_at"),
            sortable: true,
          },
          {
            key: "actions",
            label: this.$t("common.table.fields.actions"),
          },
        ],
      },
      actions: {
        planConfig: custom.tableActions.planConfig,
        planVisibility: custom.tableActions.planVisibility,
      },
    };
  },

  created() {
    this.$root.$refs[this.$route.meta.pageTitle] = this;
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.fetchPlanById();
      this.$root.$refs.bsTable.renderTable();
    },
    fetchPlanById() {
      this.$store
        .dispatch("getPlanById", this.$route.params)
        .then((response) => {
          console.log(response);
          this.$store.commit("SET_CURRENT_PLAN", response);
          this.planInfo = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editPlan(action) {
      this.$store.dispatch("setPlanAction", action).then(() => {
        this.$store
          .dispatch("setModalObj", `editPlan${action}`)
          .then(() => {
            this.$store.dispatch("setActiveModal", true);
          })
          .catch(() => {
            console.log(error);
          });
      });
    },
    editIsPrivate() {
      let action = "editIsPlanPrivate";
      let modalData = custom.confirmModalStructure.togglePlanIsPrivate;
      this.$bvModal
        .msgBoxConfirm(
          modalData.message + this.planInfo.name + " ?",
          modalData.config
        )
        .then((value) => {
          if (value) {
            this.$store.dispatch("setLoadingState", true);
            this.$store
              .dispatch(action, this.planInfo.is_private)
              .then(() => {
                // this.$store.dispatch(this.$route.meta.renderFuntion, this.$route.params || null).then(response => {
                this.$store.dispatch("setLoadingState", false);

                makeToast(custom.successMessages[action]);
                // })
              })
              .catch((error) => {
                this.$store.dispatch("setLoadingState", false);

                makeToast(
                  custom.errorMessages[error.code ? error.code : action]
                );
              });
          } else {
            this.init();
            makeToast(custom.warningMessages[action]);
          }
        });
    },
    handleEdit() {
      this.$router.push({
        name: "edit-server",
        params: { serverId: this.$route.params.serverId },
      });
    },
    handleAddPlanConfiguration() {
      this.$store.dispatch("setServerAction", "setPlanConfig").then(() => {
        this.$store
          .dispatch("setModalObj", "planConfig")
          .then(() => {
            this.$store.dispatch("setActiveModal", true);
          })
          .catch(() => {
            console.log(error);
          });
      });
    },
    handleAddOrganizationToPlan() {
      this.$store
        .dispatch("setPlanAction", "addOrganizationToPlanVisibility")
        .then(() => {
          this.$store
            .dispatch("setModalObj", "addOrganizationToPlanVisibility")
            .then(() => {
              this.$store.dispatch("setActiveModal", true);
            })
            .catch(() => {
              console.log(error);
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>